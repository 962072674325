.Card {
	composes: Card from "../liquidacion.module.css";
}

.ExpensaHeader {
    border: 2px solid var(--background-color-card);
    border-radius: var(--border-radius);
    align-items: center;
    display: inline-grid;
    padding: 10px;
    background-color: var(--background-color-card);
    box-sizing: content-box;
    overflow-x: auto;
    scroll-behavior: auto;
}

.Header {
    grid-template-columns: 50% 50%;
    grid-gap: 2rem;
    background: none;
    border: none;
    padding: 0 1rem;
    justify-content: center;
    overflow: auto;
    overflow-x: hidden;
}

@media only screen and (max-width:768px) {
    .Header {
        grid-template-columns: 100%;
        grid-gap: 1rem;
        padding: 0;
    }
}

.Titulo {
    color: var(--font-color);
    display: inline-flex;
    font-weight: bold;
    margin: 0.2ch;
    text-align: center;
    width: auto;
    font-size: large;
}

.Dataset {
    margin: 0.2ch;
}

.Key {
    color: var(--font-color)
}

.Value {
    margin: 0.5ch;
}
