.GastoRow {
	composes: GastoRow from "../gasto/gasto.module.css";
}

@media only screen and (max-width:768px) {
    .GastoRow {
        padding-right: 10px;
    }
}

.SubtotalDescripcion {
	composes: SubtotalDescripcion from "../subtotal/subtotal.module.css";
}

.GastoRow>div {
    padding: 0.75ch;
    display: inline-grid;
    box-sizing: content-box;
    border-radius: var(--border-radius);
    align-items: center;
}

.Importe {
	composes: Importe from "../gasto/gasto.module.css";
}

.TotalRubro {
    grid-template-columns: auto var(--importe-size);
    font-weight: bold;
    color: var(--font-color);
}

.SubtotalRubro {
    color: var(--font-color);
    margin-top: 0.5ch;
    margin-left: 2ch;
    font-weight: bold;
}

.TotalRubroCollapsed {
    grid-template-columns: auto var(--importe-size);
    font-weight: bold;
    color: var(--font-color);
}