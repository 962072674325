.main {
    display: grid;
    grid-template-columns: 50% 50%;
}

div#selectEdificio, div#selectLiquidacion {
    font-weight: 600;
    font-size: large;
}

.MuiSelect-select {
    color: var(--blue) !important;
    padding: 1ch 0 1ch 1ch !important;
}

.MuiFormLabel-root {
    color: var(--font-color2) !important;    
    padding-left: 1ch;
    border-radius: var(--border-radius);
}

.MuiInput-root {
    margin-top: 2ch !important;
}

.MuiInputLabel-formControl {
    padding: 0.5ch 0 1ch 1ch !important;
    border-radius: var(--border-radius) !important;
}

.MuiFormLabel-root.Mui-focused {
    color: var(--font-color2) !important;
}

.MuiFormControl-root {
    border-radius: var(--border-radius);
    min-width: 30ch !important;
    margin: 0 1rem 1rem 1rem !important;
}

.MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon {
    width: auto !important;
}


.MuiSelect-icon {
    color: var(--blue) !important;
}

.MuiInput-underline:before {
    border-color: var(--blue) !important;
}

.MuiInput-underline:after {
    border-color: var(--blue) !important;
}

.MuiPaper-root {
    background-color: var(--background-color-card) !important;
    color: var(--font-color2) !important;
}

.MuiListItem-button:hover {
    background-color: var(--background-color) !important;
}

.Liquidaciones {
    justify-self: center;
    width: 70%;
}

.Archivos {
    justify-self: center;
    width: 70%;
}

#selectEdificioUF {
    display: inline-flex;
    margin-left: 1rem;
}

#agregarUFLink {
    font-family: 'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    color: var(--blue);
    border: 1px solid #008cff96;
    align-self: center;
}

.MuiOutlinedInput-notchedOutline {
    border-color: var(--font-color2) !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--font-color) !important;
}

.MuiAutocomplete-popupIndicator {
    color: var(--font-color2) !important;
}

.MuiAutocomplete-popupIndicatorOpen {
    color: var(--font-color) !important;
}

.form-dialog-title {
    color: var(--font-color) !important;
}

.form-dialog-text {
    color: var(--font-color2) !important;
}

.MuiDialogActions-root.MuiDialogActions-spacing {
    padding-top: 0px;
}

.MuiInputBase-root, .MuiIconButton-root {
    color: var(--font-color) !important;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 0 !important;
}

.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    height: 3ch !important;
    padding: 1ch 0 1ch 0.5ch !important;
}

@media only screen and (max-width:1200px) {
    .Liquidaciones {
        width: 90%;
    }
    
    .Archivos {
        width: 90%;
    }
}
@media only screen and (max-width:768px) {
    .main {
        display: grid;
        grid-template-columns: 100%;
        grid-template-areas:
            "row-1"
            "row-2";
    }

    .Archivos {
        width: auto;
        justify-self: auto;
        margin: 0 1rem 1rem 1rem;
        grid-area: row-1;
    }

    .Liquidaciones {
        width: auto;
        justify-self: auto;
        grid-area: row-2;
    }

    #selectEdificioUF {
        display: grid;
        grid-template-columns: 100%;
        width: 100%;
        margin-left: 0;
    }

    #agregarUFLink {
        justify-self: center;
        min-width: 28ch;
        margin: 0 1rem 1rem 1rem;
    }

    .MuiFormControl-root {
        min-width: 0 !important;
    }
}
