.Main {
    text-align: center;
}

.DateTimePicker {
    width: max-content !important;
}

.Main > :global(.MuiFormControl-root) {
    width: 80% !important;
}

:global(.MuiAlert-standardSuccess) {
    /*background-color: rgb(237, 247, 237) !important;*/
}
