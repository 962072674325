.Container {
  width: 100%;
  height: 100%;
}

.Centered {
  text-align: center;    
  min-width: 70px;
}

.BottomRight {
  text-align: right;
  vertical-align: bottom;
}

.Spinner {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;    
  left: -24px;
}

.Spinner div {
  position: absolute;
  top: 6px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: var(--blue);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.Spinner div:nth-child(1) {
  left: 8px;
  animation: Spinner1 0.6s infinite;
}

.Spinner div:nth-child(2) {
  left: 8px;
  animation: Spinner2 0.6s infinite;
}

.Spinner div:nth-child(3) {
  left: 32px;
  animation: Spinner2 0.6s infinite;
}

.Spinner div:nth-child(4) {
  left: 56px;
  animation: Spinner3 0.6s infinite;
}

@keyframes Spinner1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes Spinner3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes Spinner2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
