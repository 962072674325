.NavigationItem {
    box-sizing: border-box;
    display: block;
    height: 100%;
    width: 100%;
}

.NavigationItem a {
    color: white;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    text-align: left;	
    padding: 12px 10px 16px 10px;
    display: flex;
    align-items: center;
}

.NavigationItem a:hover {
	background-color: #ffffff;
    color: black;
}

.NavigationItem a:active,
.NavigationItem a.active {
    color: black;
	background-color: #cccccc;
	border-right: 4px solid var(--blue);
}

@media (min-width: 500px) {
    .NavigationItem {
        margin: 0;
        align-items: center;
        width: auto;
    }
    
    .NavigationItem a {
        height: 100%;
        width: auto;
        padding: 16px 10px;
        border-bottom: 4px solid var(--background-color-header);
        text-align: center;
    }

    .NavigationItem a:hover {
		border-right: none;
		background-color: #ffffff;
		color: black;
	}
	
    .NavigationItem a:active,
    .NavigationItem a.active {
		border-bottom: 4px solid var(--blue);
		border-right: none;
    }
}