.RowCard {
    display: flex; 
    justify-content: space-between;
    margin: 0rem 1rem 0.5rem 1rem;
}

.RowCardTitle {
}

.RowCardValue {
    font-weight: 600;
}

.RowCardDeuda {
    color: var(--importe-deudor);
    font-weight: 600;
}

.RowCardAFavor {
    color: var(--importe-a-favor);
    font-weight: 600;
}

.RowCardVencimiento {
    font-weight: 600;
}

.RowCardVencimientoValue {
    font-weight: 600;
    align-self: center;
}
