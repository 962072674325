.Table {
    border-radius: var(--border-radius);    
    margin: 1rem 2rem 2rem 2rem;
    width: auto !important;
}

.Header {
    background-color: var(--background-color-header);
}

.Header > th {
    color: var(--blue);
    font-weight: 600;
    font-size: larger;
    border-bottom: none !important;
    text-align: center;
}

.DateHead, .TituloHead, .BotonesHead {
   width: 1ch !important;
}


.Cell {
    color: var(--font-color2) !important;
    font-weight: 600 !important;
    border-bottom: 1px solid var(--background-color) !important;
    text-align: center !important;
    font-size: inherit !important;
    padding: 0.5ch 1ch !important;
}

.Mensaje {   
    white-space: pre-wrap;
    text-align: left !important;
}

.Cell :global(.MuiAccordionSummary-content.Mui-expanded) {
    white-space: pre-wrap;
    height: auto;
    text-align: left;
    margin: 12px 0 !important;
}

.Cell :global(.MuiAccordionSummary-content) {
    height: 2ch;
    overflow: hidden;
    white-space: pre-wrap;
    text-align: left;
}

.Cell :global(.MuiPaper-root), :global(.MuiAccordionSummary-root) {
    background-color: initial !important;
    color: inherit !important;
    font-size: inherit;
}

.Cell :global(.MuiButtonBase-root) {
    align-items: flex-start !important;
}

.Cell :global(.MuiPaper-elevation1) {
    box-shadow: none !important;
}

.Cell :global(.MuiAccordionSummary-root.Mui-expanded) {
    min-height: initial !important;
}
.CellLeft {
    composes: Cell;
    text-align: left !important;
    min-width: 20rem;
}

.Botones {
    display: inline-flex !important;
}

.Row:nth-of-type(odd) {
    background-color: #5f5f5f;
}

.Row {
    background-color: var(--background-color-card);
}

:global(#DialogoAMComunicacion) :global(.MuiDialog-paperWidthSm) {
    width: calc(100% - 1rem) !important;
    max-width: initial !important;
}

@media only screen and (max-width:768px) {
}

.Button {
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
}
