.Table {
    border-radius: var(--border-radius);
}

.Header {
    background-color: var(--background-color-header);
}

.Header > th {
    color: var(--blue);
    font-weight: 600;
    font-size: larger;
    border-bottom: none !important;
    text-align: center;
}

.HeaderDownload {
    width: 3em;
}

.HeaderDownloadEmpty {
    display: none !important;
}

.Cell {
    color: var(--font-color2) !important;
    font-weight: 600 !important;
    border-bottom: 1px solid var(--background-color) !important;
    text-align: center !important;
    font-size: inherit !important;
}

.CellDownload {
    text-align: center !important;
    border-bottom: 1px solid var(--background-color) !important;
}

.Row:nth-of-type(odd) {
    background-color: #5f5f5f;
}

.Row {
    background-color: var(--background-color-card);
}

.IconoDescarga {
    color: var(--blue);
}


@media only screen and (max-width:768px) {
    .HeaderDownload {
        display: none !important;
    }
    
    .HeaderDownloadEmpty {
        display: table-cell !important;
    }
}
