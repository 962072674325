.GastoRow {
	composes: GastoRow from "../gasto/gasto.module.css";
}

.Importe {
    composes: Importe from "../gasto/gasto.module.css";
    color: #ffffff;
}

@media only screen and (max-width:768px) {
}

.SubtotalesCollapsed {
    display: flex;
    justify-content: flex-end;
}

@media only screen and (max-width:768px) {
    .GastoRow {
        padding-right: 10px;
    }
    .SubtotalesCollapsed {
        justify-content: center;
        width: auto;
        display: grid;
        grid-row-gap: 0.5ch;
        margin-left: 1ch;
    }
}
.SubtotalDescripcion {
    background: none !important;
    text-align: right;
    color: var(--font-color);
    font-weight: bold;
}

.Subtotal {
    padding: 0.75ch;
    border-radius: var(--border-radius);
    box-sizing: content-box;
    background-color: var(--cell-color);
    margin-left: 10px;
    text-align: center;
    width: 10ch;
    font-weight: bold;
    color: var(--font-color);
    white-space: pre-wrap;
}

.GastoRow>div {
    padding: 0.75ch;
    display: inline-grid;
    box-sizing: content-box;
    border-radius: var(--border-radius);
    align-items: center;
}
