.Card {
    background-color: var(--background-color-card);
    border-radius: var(--border-radius);
    min-width: 33%;
    margin-bottom: 1rem;
}

.CardHeader {
    background-color: var(--background-color-header);
    border-top-right-radius: var(--border-radius);
    border-top-left-radius: var(--border-radius);
    font-size: larger;
    display: flex; 
    justify-content: space-between;
}

.LinkHeader {
    text-decoration: none;
    color: var(--blue);
    font-weight: 600 !important;
}

.CardBody {
    padding: 1rem 0rem;
}

.CardBody hr {
    border: 1px var(--background-color) solid;
    margin: 1rem 0;
}

.RowCardDeuda {
    color: var(--importe-deudor);
    font-weight: 600;
}

.RowCardAFavor {
    color: var(--importe-a-favor);
    font-weight: 600;
}

.ArrowRight {
    width: 0; 
    height: 0; 
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid var(--blue);
    align-self: center;
    margin-right: 1rem;
}

.CardHeaderText {
    padding: 0.75rem 0.75rem 0.75rem 1rem;
}

.Titular {
    text-transform: capitalize;
} 

.RegistrarPagoContainer {
    text-align: right;
    padding-right: 1rem;
}

.Pagos {
    background-color: #929292;
    display: grid;
    padding-top: 0.75ch !important;
    padding-bottom: 0.75ch !important;
    margin-top: 0.75ch;
}

.Pagos .Pago:nth-child(odd) {
    background-color: var(--background-color-card);
}

.Pago {
    padding: 0.75ch;
    margin: 0ch 0.75ch;
    background-color: #5f5f5f;
    box-shadow: 0 0 0 1px #929292;
    color: #FFFFFF;
}

.Pago span {
    font-weight: initial;
}

.Row {
    display: flex;
    justify-content: space-between;
}

.EstadoVerificado {
    color: var(--importe-a-favor) !important;
}

.EstadoPendiente {
    color: #DEE849!important;
    white-space: pre-line;
}

.EstadoRechazado {
    color: var(--importe-deudor) !important;
}

@media only screen and (max-width:768px) {
    .Card {
        margin: 0 1rem 1rem 1rem;
    }
    .RegistrarPagoContainer {
        text-align: center;
        padding-right: 0 !important;
    }
}
