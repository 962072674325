.Logo {
    padding: 10px;
    height: 100%;
    box-sizing: border-box;
    text-align: center;
}

.Logo img {
    height: 100%;
}
