#DialogoCobranza .MuiFormControl-root {
    margin: 0 !important;
    margin-bottom: 1rem !important;
}

#DialogoCobranza .MuiAutocomplete-root {
    margin: 0 !important;
    margin-bottom: 1rem !important;
}

#formaDePago, #UF {
    padding: 18.5px 14px !important;
    height: 1.1876em !important;
}

#DialogoCobranza .MuiFormControl-root.MuiTextField-root {
    width: 100% !important;
}

#DialogoCobranza .MuiFormControl-root.MuiTextField-root.MuiFormControl-fullWidth {
    margin: 0 !important;
    width: 100% !important;
}

#DialogoCobranza .MuiDialog-paperWidthSm {
    width: 50% !important;
}

#DialogoCobranza .MuiOutlinedInput-input {
    margin-right: 0 !important;
}

#DialogoCobranza .MuiAutocomplete-input {
    margin-right: 5ch !important;
}

#DialogoCobranza .BotonesDialogo {
    display: flex;
    grid-gap: 1rem;
    padding-right: 16px;
    padding-bottom: 16px;
}

#DialogoCobranza .MuiDialogTitle-root {
    padding-bottom: 0;
}

#Dialogo .Spinner, #DialogoCobranza .Spinner {
    padding: 0 16px 16px 16px;
    width: 100%;
}

@media only screen and (max-width:768px) {
    #DialogoCobranza .MuiDialog-paperWidthSm {
        width: 100% !important;
    }
}