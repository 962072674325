.GastoRow {
    display: grid;
    grid-gap: 1ch;
    margin-bottom: 0.5ch;
    box-sizing: content-box;
}

.GastoRow>div {
    padding: 0.75ch;
    display: inline-grid;
    box-sizing: content-box;
    border-radius: var(--border-radius);
    align-items: center;
}

.GrupoTitulos {
    margin: 0.75rem 0;
}

.RowTitulos {
    display: grid;
    grid-gap: 1ch;
    box-sizing: content-box;
}

.RowTitulosFlex {
    display: flex;
    justify-content: space-between;
}
.Titulo > span {
    font-weight: initial;
}

.Titulo {
    color: var(--font-color);
    font-weight: bold;
    white-space: pre-line;
    padding-bottom: 0.25rem;
    display: block;
    box-sizing: content-box;
    align-items: center;
}

.GastoRow>.Descripcion {
    text-align: left;
    padding-left: 0;
}

@media only screen and (max-width:768px) {
    .GastoRow {
        padding-right: 10px;
    }

    .RowTitulosFlex {
        display: initial;
    }

    .RowTitulos {
        display: initial;
        grid-gap: none;
    }
}
