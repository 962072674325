.Filtros {
    display: inline-flex;
}


:global(.MuiInputLabel-formControl) {
    padding: 0 !important;
}

:global(.MuiOutlinedInput-input) {
    padding: 18.5px 14px;
    margin-right: 10ch !important;
}

.ConsorciosDropDown :global(#selectEdificioUF) {
    width: 100% !important;
    margin-left: 0 !important;
}

.LiquidacionDropDown {
    width: 100% !important;
    margin-left: 0 !important;
    font-weight: 600;
    font-size: large;
}

:global(.selectLiquidacion) {
    font-weight: 600;
    font-size: large;
}

.Table {
    border-radius: var(--border-radius);
    justify-self: center;
    width: fit-content !important;
}

.TablePagos {
    border-radius: var(--border-radius);
    justify-self: center;
}

.TableMobile {
    display: none;
}

.TableDiv {
    width: auto;
    margin: 0 1rem 1rem 1rem !important;
}

.Header {
    background-color: var(--background-color-header);
}

.Header > th {
    width: 1ch;
    color: var(--blue);
    font-weight: 600;
    font-size: larger;
    border-bottom: none !important;
    text-align: center;
    white-space: pre;
}

.Cell {
    width: 1ch;
    color: var(--font-color2) !important;
    font-weight: 600 !important;
    text-align: center !important;
    font-size: inherit !important;
    white-space: nowrap;
    padding: 0.75rem !important;
    border-bottom: none !important;
}

.Cell.Left {
    text-align: left !important;
}

.SaldoAFavor {
    color: var(--importe-a-favor) !important;
}

.UF {
    width: 4ch;
    color: var(--font-color2) !important;
    font-weight: 600 !important;
    border-bottom: 1px solid var(--background-color) !important;
    text-align: center !important;
    font-size: inherit !important;
}

.Row {
    background-color: var(--background-color-card);
    box-shadow: 0 0 0 1px var(--background-color);
}

.Row2 {
    background-color: #5f5f5f;
    box-shadow: 0 0 0 1px var(--background-color);
}

.PagosRow {
    background-color: #929292;
    box-shadow: 0 0 0 1px var(--background-color);
}

.PagosMobile {
    background-color: #929292;
    display: grid;
    padding-top: 0.75ch !important;
    padding-bottom: 0.75ch !important;
    border-radius: var(--border-radius);
    margin-top: 0.75ch;
}

.PagosMobile .PagoMobile:first-child {
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
}

.PagosMobile .PagoMobile:last-child {
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
}

.PagosMobile .PagoMobile:nth-child(odd) {
    background-color: var(--background-color-card);
}

.PagoMobile {
    padding: 0.75ch;
    margin: 0ch 0.75ch;
    background-color: #5f5f5f;
    box-shadow: 0 0 0 1px #929292;
    color: #FFFFFF;
}

.PagoMobile span {
    font-weight: initial;
}

.MobileHeader {
    font-weight: 600 !important;
    color: #FFFFFF
}

.Saldo {
    display: flex;
    justify-content: space-between;
}

.CellIconos {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
}

.Acciones {
    display: none;
}

.Fab {
    position: fixed !important;
    bottom: 1rem !important;
    display: block !important;
    right: 1rem !important;
    opacity: 0.8 !important;
}

.Fab svg {
    padding-left: 6px;
    padding-top: 6px;
    width: 1.2em;
    height: 1.2em;
}

.EstadoVerificado {
    color: var(--importe-a-favor) !important;
}

.EstadoPendiente {
    color: #DEE849!important;
    white-space: pre-line;
}

.EstadoRechazado {
    color: var(--importe-deudor) !important;
}

.RowHeaderMobile {
    display: flex;
    justify-content: space-between;
}

.RowHeaderMobile :global(.MuiIconButton-sizeSmall) {
    padding: 0 !important;
}

.Button {
    font-family: 'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

@media only screen and (max-width:768px) {
    .Filtros {
        display: inline-flex;
        flex-direction: column;
        width: 100%;
    }

    :global(#selectLiquidacionContainer) {
        display: grid;
        grid-template-columns: 100%;
        width: 100% !important;
        margin-left: 0 !important;
    }

    .Table {
        display: none;
    }

    .TableMobile {
        display: block;
        margin-bottom: 80px;
    }

    .Row, .Row2 {
        padding: 0.75ch;
    }

    .Row > div {
        padding-bottom: 0.5ch;
    }

    .Row:nth-child(even) {
        background-color: #5f5f5f;
    }

    .Saldo:last-child {
        padding-bottom: 0;
    }

    .TableMobile .Row:first-child {
        border-top-left-radius: var(--border-radius);
        border-top-right-radius: var(--border-radius);
    }
    
    .TableMobile .Row:last-child {
        border-bottom-left-radius: var(--border-radius);
        border-bottom-right-radius: var(--border-radius);
    }

    .BusquedaContainer {
        width: 100%;
    }

    .Busqueda {
        text-align: center;
    }

    .Acciones {
        display: block;
        text-align: center;
    }

    .BotonesAccionPago {
        display: flex;
        justify-content: space-around;
        padding-top: 0.75ch;
    }
}
