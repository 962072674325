.ArrowUp {
	composes: ArrowUp from "../../liquidacion.module.css";
}

.ArrowDown {
	composes: ArrowDown from "../../liquidacion.module.css";
}

.Grupo {
	composes: Card from "../../liquidacion.module.css";
    align-items: center;
    justify-content: normal;
    transition: transform 0.3s ease-out;
}

.Collapsed {
	composes: Card from "../../liquidacion.module.css";
    display: grid;
    align-items: normal;
	grid-template-columns: auto auto;
	padding: 0;
	margin: 0;
}

@media only screen and (max-width:768px) {
    .GastoRow {
        padding-right: 10px;
    }
    .Collapsed {
        justify-content: left;
        width: auto;
        display: grid;
		grid-template-columns: auto;
        grid-row-gap: 0.5ch;
        margin-left: 1ch;
    }
}


.Titulo {
    width: 100%;
    color: var(--font-color);
    display: inline-flex;
    font-weight: bold;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
}

.SubtotalRubro {
    color: var(--font-color);
    margin-top: 0.5ch;
    margin-left: 2ch;
    font-weight: bold;
}

.Hidden {
	display: none;
}
