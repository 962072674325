.Toolbar {
    height: 4rem;
    background-color: var(--background-color-header);
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    z-index: 90;
    position: fixed;
    top: 0;
    width: 100%;
}

.Toolbar nav {
    height: 100%;
}

.Logo {
    height: 80%;
}
@media (max-width: 499px) {
    .DesktopOnly {
        display: none;
    }
}
@media (min-width: 500px) {
    .MobileOnly {
        display: none;
    }
}