.Card {
    padding: 1rem;
    box-sizing: content-box;
    display: grid;
    background-color: var(--background-color-card);
    border: 2px solid var(--background-color-card);
    border-radius: var(--border-radius);
    margin: 1rem;
    overflow-x: auto;
}

.Section {
    background-color: var(--background-color-section);
    padding: 0.2em 0;
    margin: 1em;
    border-radius: var(--border-radius);
}

.SectionHeader {
    overflow: auto;
    text-align: center;
    color: var(--blue);
    font-weight: bold;
    font-size: larger;
}

.ArrowUp {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid var(--font-color);
    margin-right: 1ch;
    align-self: center;
}

.ArrowDown {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid var(--font-color);
    margin-right: 1ch;
    align-self: center;
}
