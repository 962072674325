.GastoRow {
    display: grid;
    grid-gap: 1ch;
    margin-bottom: 0.5ch;
    box-sizing: content-box;
}

@media only screen and (max-width:768px) {
    .GastoRow {
        padding-right: 10px;
    }
}

.GastoRow>.Descripcion {
    text-align: left;
    padding-left: 0;
}

.GastoRow>div {
    padding: 0.75ch;
    display: inline-grid;
    box-sizing: content-box;
    border-radius: var(--border-radius);
    align-items: center;
}

.GastoRow:nth-child(odd)>div:not(.Titulo) {
    background-color: var(--cell-color);
}

.GastoRow:nth-child(even)>div:not(.Titulo) {
    background-color: var(--cell-color2);
}

.Importe {
    text-align: right;
}
.SubtotalDescripcion {
    background: none !important;
    text-align: right;
    color: var(--font-color);
    font-weight: bold;
}
