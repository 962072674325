#FormCrearComunicacion .MuiInputBase-input {
    padding-left: 1ch !important;
}

#FormCrearComunicacion .MuiFormGroup-root {
    margin: 0rem 1rem 1rem 1rem !important;
    align-content: center !important;
}

#FormCrearComunicacion .MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal {
    width: max-content !important;
    align-self: center !important;
}

#FormCrearComunicacion .MuiInputLabel-formControl {
    padding: 0 !important;
}

#FormCrearComunicacion #selectEdificioUF {
    align-self: center;
}

#selectEdificioUF {
    text-align: initial;
}

@media only screen and (max-width:768px) {
    #FormCrearComunicacion .MuiFormControl-root {
        min-width: initial !important;
        margin: 0 0 1rem 0 !important;
    }
    #FormCrearComunicacion .MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal {
        width: 100% !important;
    }
}
