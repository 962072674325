.Mensaje {
    text-align: center;
    margin: 0 10ch
}

@media only screen and (max-width:768px) {
    .Mensaje {
        margin: 0 2ch
    }
}
