.Liquid {
    color:#008cff;
}

.PuntoAmarillo {
    color: #ecec3a;
}

.AR {
    color: #FFF; 
}

.Titulo {
    text-align: center;
    color: #FFF; 
}

.Grid {
    margin: 0 2ch; 
    text-align: center;
    display: grid;
    grid-template-columns: 100%;
    grid-template-areas:
        "row-1"
        "row-2";
}

.TextoBienvenida {
    font-weight: normal;
    width: 70%;
    display: inline-block;
    grid-area: row-1;
    justify-self: center;
}

@media only screen and (max-width:768px) {
    .TextoBienvenida {
        width: auto;
        grid-area: row-2;
    }
}
