

#DialogoAsociarUF .botonAceptar {
    color: var(--blue) !important;
    font-weight: bold !important;
}

#DialogoAsociarUF .botonCancelar {
    color: var(--font-color) !important;
}

#DialogoAsociarUF .MuiFormControl-root.MuiTextField-root {
    width: 100% !important;
}

#DialogoAsociarUF .MuiFormControl-root.MuiTextField-root.MuiFormControl-fullWidth {
    margin: 0 !important;
    width: 100% !important;
    min-width: initial !important;
}
