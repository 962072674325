.Comunicaciones {
    margin: 1rem 2rem 1rem 2rem;
}

.TituloComunicacion{
    font-size: initial;
}

:global(.MuiAccordionDetails-root) .ComunicacionMensaje {
    background-color: var(--background-color);
    border-radius: 0 0 var(--border-radius) var(--border-radius) !important;
    padding: 1.5ch 3ch !important;
    white-space: pre-wrap;
}

.ComunicacionesBody{
    display: grid !important;
    grid-gap: 1.5ch;
}

.Comunicaciones :global(.MuiTableCell-root) {
    font-family: inherit !important;
}

.Comunicaciones :global(.MuiAccordionSummary-root){
    background-color: var(--background-color-header) !important;
    color: var(--blue) !important;
    font-size: larger;
    font-weight: 600 !important;
}

.Comunicaciones :global(.MuiAccordion-rounded) {
    width: 100% !important;
}

.Comunicaciones :global(.MuiAccordion-rounded:last-child) {
    border-bottom-left-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
}

.Comunicaciones :global(.MuiAccordion-rounded:first-child) {
    border-top-left-radius: var(--border-radius) !important;
    border-top-right-radius: var(--border-radius) !important;
    
}

.Comunicaciones :global(.MuiAccordionSummary-root) {
    border-radius: var(--border-radius) var(--border-radius) var(--border-radius) var(--border-radius) !important;
}

.Comunicaciones :global(.MuiAccordionSummary-root.Mui-expanded) {
    min-height: 64px;
    border-radius: var(--border-radius) var(--border-radius) 0 0 !important;
}


.Comunicaciones :global(.MuiAccordionSummary-content) {
    justify-content: space-between;
}

.Comunicaciones :global(.MuiAccordionSummary-content.Mui-expanded) {
    margin: 0.5ch !important;
}

.Comunicaciones :global(.MuiAccordionSummary-root.Mui-expanded) {
    min-height: 48px !important;
}

.Comunicaciones :global(.MuiIconButton-root) {
    padding: 0.5ch 1ch
}

.Comunicaciones :global(.MuiPaper-elevation1) {
    box-shadow: initial !important;
}

.Comunicaciones :global(.MuiAccordion-root.Mui-expanded) {
    margin: 0 !important;
}

.Comunicaciones :global(.MuiAccordionDetails-root) {
    padding: 1.5ch !important;
}

.Comunicaciones :global(.MuiAccordionSummary-root) {
    transition: min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, 
                background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, 
                border-radius 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
}

.Comunicaciones :global(.MuiAccordionSummary-root.Mui-expanded) {
    transition: min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, 
                background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, 
                border-radius 350ms ease-out 0ms !important;
}


@media only screen and (max-width:768px) {
    .Comunicaciones {
        margin: 0 1rem 1rem 1rem;
    }
}
