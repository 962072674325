:root {
	--importe-size: 13ch;
	--border-radius: 10px;
	--cell-color: rgba(0, 110, 255, 0.35);
	--cell-color2: rgba(100, 165, 255, 0.35);
	--font-color: #FFFFFF;
	--font-color2: #C9C9C9;
	--background-color: #363636;
	--background-color-card: #474747;
	--background-color-header: #181818;
	--background-color-section: #696969;
	--blue: #008cff;
	--importe-a-favor: #00cc00;
	--importe-deudor: #ff5f5f;
}

html {
	font-family: 'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	color: var(--font-color2);
}

.MuiPaper-root, .MuiTypography-body1, .MuiTypography-body2, .MuiTypography-caption, .MuiBadge-badge {
    font-family: 'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}
[class^='MuiTypography'], [class*=' MuiTypography'] {
	font-family: 'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

.MuiAlert-root {
	margin: 1rem !important;
}

.MuiInputLabel-shrink {
    padding-left: 0 !important;
}

.MuiOutlinedInput-multiline {
    padding: 18.5px 0px !important;
}

.MuiButton-textPrimary {
	color: var(--blue) !important;
}

.MuiButton-outlinedPrimary {
	color: var(--blue) !important;
	border-color: var(--blue) !important;
}

.MuiButton-textSecondary {
	color: var(--font-color) !important;
}

.MuiButton-outlinedSecondary {
	color: var(--font-color) !important;
	border-color: var(--font-color) !important;
}

.MuiPickersClockNumber-clockNumber {
	color: var(--font-color) !important;
}

.MuiPickersYear-root {
	color: var(--font-color) !important
}

.MuiTypography-colorTextSecondary {
	color: var(--font-color2) !important
}

.MuiButton-containedPrimary {
	background-color: var(--blue) !important;
}

.MuiIconButton-sizeSmall {
    font-size: 16px !important;
}

body {
	background-color: var(--background-color);
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a {
	color: #FFFFFF;
}

::-webkit-scrollbar {
	/* background-color: transparent; */
	/* color: white; */
}
