.Wrapper {
    display: inline-flex;
}

@media only screen and (max-width:768px) {
    .Wrapper {
        display: block;
        text-align: center;
    }
}
