.MuiPickersCalendarHeader-dayLabel {
    color: var(--font-color) !important;
    text-transform: uppercase;
}

.MuiPickersCalendarHeader-iconButton {
    background-color: initial !important;
}

.MuiPickersToolbar-toolbar {
    background-color: var(--background-color) !important;
}

.MuiPickersToolbarText-toolbarBtnSelected {
    text-transform: capitalize;
}

.MuiPickersCalendarHeader-transitionContainer {
    text-transform: capitalize;
    color: var(--font-color) !important;    
}

.MuiPickerDTTabs-tabs {
    background-color: var(--background-color-section) !important;
}

[class*='PrivateTabIndicator-colorSecondary'] {
    background-color: var(--blue) !important;
}

.MuiTab-textColorInherit.Mui-selected {
    background-color: var(--blue);
}

.MuiPickersDay-daySelected {
    background-color: var(--blue) !important;
}

.MuiPickersDay-dayDisabled {
    text-decoration: line-through !important;
}

.MuiInputBase-input.Mui-disabled {
    color: var(--background-color-section) !important;
}
