.GastoRow {
    display: grid;
    grid-gap: 1ch;
    margin-bottom: 0.5ch;
    margin-top: 0.5ch;
    box-sizing: content-box;
}

@media only screen and (max-width:768px) {
    .GastoRow {
        padding-right: 10px;
    }
}

.GastoRow>div {
    padding: 0.75ch;
    display: inline-grid;
    box-sizing: content-box;
    border-radius: var(--border-radius);
    align-items: center;
}

.Titulo {
    color: var(--font-color);
    font-weight: bold;
    margin: 2px;
    text-align: center;
    align-content: flex-end;
	width: auto;
	white-space: pre-line;
}

.GastoRow>.Descripcion {
    text-align: left;
    padding-left: 0;
}
