.Card {
	composes: Card from "../../liquidacion.module.css";
}

.SectionHeader {
    overflow: auto;
    text-align: center;
    color: var(--blue);
    font-weight: bold;
    font-size: larger;
    white-space: pre-wrap;
}
